import React, { useEffect } from "react";
import { Table } from "antd";
import { Permission } from "models/adminModel";

import PermissionCheckbox from "./PermissionCheckbox";

interface Props {
  permissions: Permission[];
  onPermissionChange: (isSelected: boolean, permissions: Permission[]) => void;
  disabledCheckBox: boolean;
}

const PermissionsTable: React.FC<Props> = ({
  permissions,
  onPermissionChange,
  disabledCheckBox = false,
}) => {
  useEffect(() => {
    const isSelected = permissions.some((p) =>
      Object.values(p.permissions).some(Boolean)
    );
    onPermissionChange(isSelected, permissions);
  }, [permissions, onPermissionChange]);

  const updatePermissionValues = (
    currentPermissions: Permission["permissions"],
    type: "read" | "create" | "update" | "delete",
    checked: boolean
  ) => {
    const updatedPermissions = { ...currentPermissions, [type]: checked };

    if (
      (type === "create" || type === "update" || type === "delete") &&
      checked
    ) {
      updatedPermissions.read = true;
    }

    if (type === "read" && !checked) {
      if (updatedPermissions.create !== undefined)
        updatedPermissions.create = false;
      if (updatedPermissions.update !== undefined)
        updatedPermissions.update = false;
      if (updatedPermissions.delete !== undefined)
        updatedPermissions.delete = false;
    }

    return updatedPermissions;
  };

  const handleCheckboxChange = (
    permissionName: string,
    type: "read" | "create" | "update" | "delete",
    checked: boolean
  ) => {
    const updatedPermissions = permissions.map((permission) =>
      permission.name === permissionName
        ? {
            ...permission,
            permissions: updatePermissionValues(
              permission.permissions,
              type,
              checked
            ),
          }
        : permission
    );
    onPermissionChange(
      updatedPermissions.some((p) =>
        Object.values(p.permissions).some(Boolean)
      ),
      updatedPermissions
    );
  };

  const renderCheckbox = (
    type: "read" | "create" | "update" | "delete",
    record: Permission
  ) => {
    const isPermissionDefined = record.permissions[type] !== undefined;

    return (
      isPermissionDefined && (
        <PermissionCheckbox
          disabled={disabledCheckBox}
          checked={record.permissions[type] as boolean}
          onChange={(checked) =>
            handleCheckboxChange(record.name, type, checked)
          }
        />
      )
    );
  };

  const columns = [
    {
      title: "Permission",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Read",
      dataIndex: "read",
      key: "read",
      render: (_: string, record: Permission) => renderCheckbox("read", record),
    },
    {
      title: "Create",
      dataIndex: "create",
      key: "create",
      render: (_: string, record: Permission) =>
        renderCheckbox("create", record),
    },
    {
      title: "Update",
      dataIndex: "update",
      key: "update",
      render: (_: string, record: Permission) =>
        renderCheckbox("update", record),
    },
    {
      title: "Delete",
      dataIndex: "delete",
      key: "delete",
      render: (_: string, record: Permission) =>
        renderCheckbox("delete", record),
    },
  ];

  return (
    <Table
      dataSource={permissions}
      columns={columns}
      rowKey="name"
      pagination={false}
    />
  );
};

export default PermissionsTable;
