import { Admin } from "./types";

export const mockAdmins: Admin[] = [
  {
    email: "superadmin@example.com",
    role: "Super Admin",
    managedTenants: [],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "admin_system@example.com",
    role: "Blue Admin",
    managedTenants: ["KPMG", "KMS"],
    status: "Invited",
    inviteState: "Invited",
  },
  {
    email: "admin3@example.com",
    role: "Blue Admin",
    managedTenants: ["KPMG", "KMS", " Johnson & Johnson", "Google Inc"],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "superadmin@example.com.vn",
    role: "Super Admin",
    managedTenants: [],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "admin_system@example.com.vn",
    role: "Blue Admin",
    managedTenants: ["KPMG", "KMS"],
    status: "Invited",
    inviteState: "Invited",
  },
  {
    email: "admin3@example.com.vn",
    role: "Blue Admin",
    managedTenants: ["KPMG", "KMS", " Johnson & Johnson", "Google Inc"],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "superadmin@example.com.us",
    role: "Super Admin",
    managedTenants: [],
    status: "Active",
    inviteState: "Active",
  },
  {
    email: "admin_system@example.com.us",
    role: "Blue Admin",
    managedTenants: ["KPMG", "KMS"],
    status: "Invited",
    inviteState: "Invited",
  },
  {
    email: "admin3@example.com.us",
    role: "Blue Admin",
    managedTenants: [
      "KPMG",
      "KMS",
      " Johnson & Johnson",
      "Google Inc",
      "KPMG1",
      "KMS1",
      " Johnson & Johnson1",
      "Google Inc1",
      "KPMG2",
      "KMS2",
      " Johnson & Johnson2",
      "Google Inc2",
    ],
    status: "Active",
    inviteState: "Active",
  },
];
