const figmaColor = {
  purple900: "#7a27ef",
  purple800: "#9146fc",
  purple700: "#a463ff",
  purple500: "#c79Cff",
  gray75: "#fafafa",
  gray100: "#f5f5f5",
  gray200: "#e6e6e6",
  gray400: "#b1b1b1",
  gray600: "#6e6e6e",
  gray700: "#454545",
  gray800: "#212121",
  gray900: "#000000",
  gray900t2: "#00000033",
  gray50t20: "#ffffff33",
  sky500: "#06bdf9",
  sky300: "#88e9ff",
  sky: "#06bdf9",
  space1100: "#303c4a",
  space500: "#aab0b6",
  white15: "#ffffff26",
  white50: "#ffffff80",
  white: "#ffffff",
};

const color = {
  gradient: {
    purpleBlue: "linear-gradient(109.1deg, #510DBC 27.96%, #2F4CCA 67.06%)",
    lightPurple: "linear-gradient(to bottom, #FFFFFF4D 0%, #FFFFFF00 100%)",
    purple: "linear-gradient(180deg, #4906A7 0%, #9146FC 100%)",
    sky: "linear-gradient(180deg, #06BDF9 0%, #46D9FF 100%)",
    space: "linear-gradient(180deg, #303C4A 0%, #7F8790 100%)",
  },
  footer: {
    title: "#B57EFF",
  },
  button: {
    primary: figmaColor.purple900,
    primaryHover: "#C4B5FD",
  },
  checkbox: {
    primary: figmaColor.purple900,
  },
  menu: {
    items: {
      hoverBg: figmaColor.purple900,
      selectedColor: figmaColor.purple900,
      hoverColor: "#B57EFF",
      color: "#FFFFFF",
    },
    icon: {
      fill: figmaColor.purple900,
    },
  },
  icon: {
    default: "#FFFFFF",
    social: "#343330",
    social2: figmaColor.purple900,
    black: "#000000",
  },
  radio: {
    background: figmaColor.white,
    primary: figmaColor.purple900,
  },
  table: {
    header: {
      bg: "#FFFFFF",
      split: "#FFFFFF",
    },
    border: figmaColor.gray900t2,
  },
  tag: {
    blue: "#00338E",
    turqouis: "#05C5B2",
  },
};

const size = {
  expandableIndent: 16,
  tableBottom: 16,
};

const theme = {
  color,
  figmaColor,
  size,
  font: {
    base: ["Inter", "sans-serif"],
  },
};

export default theme;
