import React, { useState, useMemo, useCallback } from "react";
import { Row, Col, Empty } from "antd";
import { TaskSection } from "components/Section";
import FilterSlider from "components/FilterSlider";
import FilterOptions from "components/FilterOptions";
import { ImpactedTask } from "models/workforceModel";
import { isEmpty } from "lodash";
import { updateTaskImpactSlider } from "services/workforceService";
import { PageSizeSelect, PaginationList } from "components/Pagination";

import TextSection from "./TextSection";

interface Props {
  data: ImpactedTask[];
  isViewHeader?: boolean;
  isViewTeam?: boolean;
}

const TaskImpact: React.FC<Props> = ({
  data = [],
  isViewHeader = true,
  isViewTeam = true,
}) => {
  const [pageSize, setPageSize] = useState<number>(6);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filterOption, setFilterOption] = useState<string>("timeCount");
  const [filterHours, setFilterHours] = useState<number>(
    useMemo(() => {
      const savedSlider = isViewHeader
        ? localStorage.getItem("taskImpactSlider") || "0"
        : "0";
      return Number.isNaN(Number.parseInt(savedSlider))
        ? 0
        : Number.parseInt(savedSlider);
    }, [isViewHeader])
  );

  const handlePageSizeChange = useCallback((value: number) => {
    setPageSize(value);
    setCurrentPage(1);
  }, []);

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);

  const handleSortChange = useCallback((value: string) => {
    setFilterOption(value);
    setCurrentPage(1);
  }, []);

  const handleFilterChange = useCallback((size: number) => {
    updateTaskImpactSlider(size);
    setFilterHours(size);
    setCurrentPage(1);
  }, []);

  const maxTimeSaved = useMemo(
    () => Math.round(Math.max(...data.map((task) => task.monthlyTimeSaved))),
    [data]
  );

  const filteredTasks = useMemo(() => {
    const tasks = [...data];
    if (filterOption === "timeCount") {
      tasks.sort((a, b) => b.monthlyTimeSaved - a.monthlyTimeSaved);
    } else if (filterOption === "employeeCount") {
      tasks.sort((a, b) => b.totalEmployees - a.totalEmployees);
    }
    return tasks.filter((task) => task.monthlyTimeSaved >= filterHours);
  }, [data, filterOption, filterHours]);

  const paginatedTasks = useMemo(() => {
    return filteredTasks.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );
  }, [filteredTasks, currentPage, pageSize]);

  const totalTasks = filteredTasks.length;

  if (isEmpty(data)) {
    return <Empty />;
  }

  return (
    <div className="w-full">
      <TextSection
        className="text-2xl font-light leading-8 w-full py-2"
        text="Where the opportunity comes from:"
      />
      <TextSection
        className="text-lg font-normal leading-7 pb-4"
        text="The following tasks are where a majority of the potential opportunity comes from."
      />

      {isViewHeader && (
        <Row gutter={16} className="mb-4 flex items-center">
          <Col span={8}>
            <FilterOptions
              filterOption={filterOption}
              onChange={handleSortChange}
              orderByLabel="Order by:"
              radioOptions={[
                { value: "timeCount", label: "Hours per month" },
                { value: "employeeCount", label: "Employee count" },
              ]}
            />
          </Col>

          <Col span={6} offset={2}>
            <FilterSlider
              filterMaxHours={maxTimeSaved}
              filterHours={filterHours}
              onFilterChange={handleFilterChange}
            />
          </Col>

          <Col span={4} offset={4} className="flex justify-end">
            <PageSizeSelect
              pageSize={pageSize}
              onPageSizeChange={handlePageSizeChange}
            />
          </Col>
        </Row>
      )}

      <Row gutter={[16, 16]} className="m-4">
        {paginatedTasks.map((task, index) => (
          <Col key={index} span={12}>
            <TaskSection {...task} isViewTeam={isViewTeam} />
          </Col>
        ))}
      </Row>

      <Row className="pt-2">
        <PaginationList
          currentPage={currentPage}
          pageSize={pageSize}
          total={totalTasks}
          onPageChange={handlePageChange}
        />
      </Row>
    </div>
  );
};

export default TaskImpact;
