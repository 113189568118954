import React from "react";
import { Checkbox, ConfigProvider } from "antd";
import theme from "assets/styles/theme";

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

const PermissionCheckbox: React.FC<Props> = ({
  checked,
  onChange,
  disabled = false,
}) => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorPrimaryHover: theme.color.checkbox.primary,
            colorPrimary: theme.color.checkbox.primary,
            colorBorder: disabled
              ? theme.figmaColor.gray50t20
              : theme.color.checkbox.primary,
          },
        },
      }}
    >
      <Checkbox
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
    </ConfigProvider>
  );
};

export default PermissionCheckbox;
