import React, { useState, useEffect, useCallback } from "react";
import { Button, Col, Input, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Table from "components/Table/Table";
import { SearchIcon } from "components/Icon";
import theme from "assets/styles/theme";
import { useNavigate } from "react-router-dom";
import { Tenant } from "models/adminModel";
import useFetchData from "hooks/useFetchData";
import ErrorPage from "components/Router/ErrorPage";
import { getTenants } from "services/adminService";

import TenantLayout from "../TenantLayout";

import CreateTenantModal from "./CreateTenantModal";
import { tenantColumns } from "./tenantColumns";

const TenantList: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState<Tenant[]>([]);
  const [modalVisible, setModalVisible] = useState(false);

  const {
    data: tenants = [],
    loading,
    error,
    refetch,
  } = useFetchData<Tenant[]>(getTenants);
  const navigate = useNavigate();

  const filterTenants = useCallback((data: Tenant[], search: string) => {
    return data.filter((tenant) =>
      tenant.name.toLowerCase().includes(search.trim().toLowerCase())
    );
  }, []);

  useEffect(() => {
    if (tenants.length > 0) {
      setFilteredData(filterTenants(tenants, searchText));
    }
  }, [tenants, searchText, filterTenants]);

  const handleSearch = (value: string) => setSearchText(value);

  const handleCreate = () => {
    setModalVisible(false);
    refetch();
  };

  const handleButtonClick = (key: string) =>
    navigate(`/admin/tenant-management/${key}`);

  const handleUpdate = () => console.log("Update action triggered");

  const handleDelete = () => console.log("Delete action triggered");

  return (
    <TenantLayout>
      <Row gutter={[0, 16]} className="p-8">
        <Col span={24}>
          <Input
            placeholder="Find a tenant"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            className="font-medium float-left"
            style={{ width: 300, height: 44 }}
            prefix={<SearchIcon stroke={theme.figmaColor.gray800} />}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="float-right"
            onClick={() => setModalVisible(true)}
          >
            Create Tenant
          </Button>
        </Col>
        <Col span={24}>
          <Table
            columns={tenantColumns(
              handleButtonClick,
              handleCreate,
              handleUpdate,
              handleDelete
            )}
            rowKey="id"
            dataSource={filteredData}
            rowClassName={"text-base"}
            loading={loading}
            pagination={false}
            scroll={{ y: 400, x: 800 }}
          />
          {error && <ErrorPage />}
        </Col>
      </Row>

      <CreateTenantModal
        visible={modalVisible}
        onCreate={handleCreate}
        onCancel={() => setModalVisible(false)}
      />
    </TenantLayout>
  );
};

export default TenantList;
