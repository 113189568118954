import React, { useState } from "react";
import { Button, Col, Row, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import Table from "components/Table/Table";
import { ColumnsType } from "antd/es/table";
import { BoxLayout, CardLayout, MainLayout } from "components/Layout";
import { ADMIN_ROLE } from "common/constants";

import DetailActions from "../TenantPage/DetailActions";
import StatusText from "../Shared/StatusText";

import { Admin } from "./types";
import { mockAdmins } from "./mockData";
import TenantListModal from "./TenantListModal";
import InviteUserModal from "./InviteUserModal";

const { SUPER_ADMIN, BLUE_ADMIN } = ADMIN_ROLE;

const AdminUserList: React.FC = () => {
  const [admins, setAdmins] = useState<Admin[]>(mockAdmins);
  const [inviteModalVisible, setInviteModalVisible] = useState(false);
  const [tenantListModalVisible, setTenantListModalVisible] = useState(false);
  const [selectedTenants, setSelectedTenants] = useState<string[]>([]);

  const handleUpdate = () => {
    console.log("Edit clicked");
  };

  const handleResendInvite = () => {
    console.log("Re-send invite clicked");
  };

  const handleShowTenantListModal = (tenants: string[]) => {
    setSelectedTenants(tenants);
    setTenantListModalVisible(true);
  };

  const handleCloseTenantListModal = () => {
    setTenantListModalVisible(false);
  };

  const handleInviteUser = (email: string, role: string, tenants: string[]) => {
    const newAdmin: Admin = {
      email,
      role,
      managedTenants: tenants,
      status: "Invited",
      inviteState: "Invited",
    };

    setAdmins((prevAdmins) => [newAdmin, ...prevAdmins]);
    message.success(`Invite sent to ${email}`);
  };

  const renderManagedTenants = (tenants: string[], role: string) => {
    if (role === SUPER_ADMIN) return "N/A";
    if (role === BLUE_ADMIN) {
      const sortedTenants = tenants.sort();
      if (tenants.length <= 2) return sortedTenants.join(", ");
      return (
        <>
          {sortedTenants.slice(0, 2).join(", ")}
          <br />
          <a
            className="italic"
            onClick={() => handleShowTenantListModal(sortedTenants)}
          >
            and {tenants.length - 2} more.
          </a>
        </>
      );
    }
    return "-";
  };

  const columns: ColumnsType<Admin> = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Managed Tenants",
      key: "managedTenants",
      render: (_, record) =>
        renderManagedTenants(record.managedTenants, record.role),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <StatusText status={status} />,
    },
    {
      title: "Actions",
      key: "actions",
      width: 120,
      render: (_, record) => (
        <DetailActions
          onUpdate={handleUpdate}
          onResendInvite={
            record.status === "Invited" ? handleResendInvite : undefined
          }
        />
      ),
    },
  ];

  return (
    <MainLayout>
      <CardLayout>
        <BoxLayout className="bg-white shadow-gradient">
          <Row gutter={[0, 16]} className="p-8">
            <Col span={24}>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                className="float-right"
                onClick={() => setInviteModalVisible(true)}
              >
                Invite User
              </Button>
            </Col>
            <Col span={24}>
              <Table
                columns={columns}
                rowClassName={"text-base"}
                dataSource={admins}
                rowKey="email"
                scroll={{ y: 600 }}
                pagination={false}
              />
              <TenantListModal
                visible={tenantListModalVisible}
                tenants={selectedTenants}
                onClose={handleCloseTenantListModal}
              />
              <InviteUserModal
                visible={inviteModalVisible}
                tenants={["Tenant 1", "Tenant 2", "Tenant 3"]}
                onClose={() => setInviteModalVisible(false)}
                onInvite={handleInviteUser}
                existingAdmins={admins.map((admin) => admin.email)}
              />
            </Col>
          </Row>
        </BoxLayout>
      </CardLayout>
    </MainLayout>
  );
};

export default AdminUserList;
