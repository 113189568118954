import graphQLClient from "common/client/graphQLClient";
import {
  combineRolePermissions,
  transformPermissions,
  UpsertRolePayload,
} from "models/adminModel";
import HttpClient from "common/client/httpClient";

import {
  GET_ROLE,
  LIST_ROLES,
  LIST_TENANT,
  LIST_TENANT_PERMISSIONS,
  UPSERT_ROLE,
} from "./adminQueries";

export const getTenants = async () => {
  const response = await graphQLClient.query({
    query: LIST_TENANT,
  });
  return response.data.listTenant;
};

export const uploadCSV = async (formData: FormData) => {
  const header = {
    "Content-Type": "multipart/form-data",
  };
  const response = await HttpClient.post(header, "/upload", formData);

  return response.data;
};

export const getRoles = async (tenantId: string) => {
  const response = await graphQLClient.query({
    query: LIST_ROLES,
    variables: { tenantId },
  });
  return response.data.listRolesWithNbOfPermissions;
};

export const upsertRole = async (data: UpsertRolePayload) => {
  const response = await graphQLClient.mutate({
    mutation: UPSERT_ROLE,
    variables: { input: data },
  });
  return response;
};

export const getRoleDetail = async (id: string) => {
  const response = await graphQLClient.mutate({
    mutation: GET_ROLE,
    variables: {
      id,
    },
  });
  return response.data.getRoleDetail;
};

export const getTenantPermissions = async () => {
  const response = await graphQLClient.query({
    query: LIST_TENANT_PERMISSIONS,
  });
  return response.data.listTenantUserPermissions;
};

export const getRolePermissions = async (id?: string) => {
  const allPermissions = await getTenantPermissions();
  let rolePermissions = transformPermissions(allPermissions);
  let name = "";
  let description = "";
  let isSystemRole = false;

  if (id) {
    const data = await getRoleDetail(id);
    rolePermissions = combineRolePermissions(rolePermissions, data.permissions);
    name = data.name;
    description = data.description;
    isSystemRole = data.isSystemRole;
  }

  return {
    name,
    description,
    rolePermissions,
    isSystemRole,
  };
};
