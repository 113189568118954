import React, { useState, useEffect } from "react";
import { Button, Input } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Table from "components/Table/Table";
import { RowSection } from "components/Section";
import { SearchIcon } from "components/Icon";
import theme from "assets/styles/theme";
import { TeamRole } from "models/workforceModel";

interface Props {
  isHideTeam?: boolean;
  roles: TeamRole[];
  enableSearch?: boolean;
  onRowChange: (value: string) => void;
}

const SearchRoleTable: React.FC<Props> = ({
  isHideTeam = false,
  roles,
  enableSearch = false,
  onRowChange,
}) => {
  const [searchText, setSearchText] = useState("");
  const [filteredRoles, setFilteredRoles] = useState(roles);

  useEffect(() => {
    if (enableSearch) {
      setFilteredRoles(
        roles.filter((role) =>
          role.title.toLowerCase().includes(searchText.trim().toLowerCase())
        )
      );
    } else {
      setFilteredRoles(roles);
    }
  }, [roles, searchText, enableSearch]);

  const handleButtonClick = (key: string) => {
    onRowChange(key);
  };

  const handleSearch = (value: string) => {
    setSearchText(value);
  };

  const columns = [
    {
      title: "Role Title",
      dataIndex: "title",
      key: "title",
      render: (_: string, record: TeamRole) => (
        <div
          className="text-base font-normal leading-6 truncate w-auto"
          title={record.title}
        >
          {record.title}
        </div>
      ),
    },
    {
      title: "Employees",
      dataIndex: "totalEmployees",
      key: "totalEmployees",
      render: (_: string, record: TeamRole) =>
        isHideTeam ? (
          <RowSection value={record.totalEmployees} />
        ) : (
          <RowSection
            value={record.totalEmployees}
            description={`in ${record.totalTeams} team(s)`}
          />
        ),
    },
    {
      title: "Productivity Gain",
      dataIndex: "annualProdGain",
      key: "annualProdGain",
      render: (_: string, record: TeamRole) => (
        <RowSection
          value={record.annualProdGain}
          description={"per year"}
          isViewcurrency={true}
        />
      ),
    },
    {
      title: "Time Saved",
      dataIndex: "annualTimeSaved",
      key: "annualTimeSaved",
      render: (_: string, record: TeamRole) => (
        <RowSection
          value={record.annualTimeSaved}
          description={"hours per year"}
        />
      ),
    },
    {
      title: "Detail",
      key: "actions",
      render: (_: string, record: TeamRole) => (
        <Button
          type="primary"
          icon={<RightOutlined />}
          iconPosition="end"
          onClick={() => handleButtonClick(record.title as string)}
        >
          <span className="text-xs">VIEW ROLE</span>
        </Button>
      ),
    },
  ];

  return (
    <div>
      {enableSearch && (
        <div className="flex items-center pb-4">
          <Input
            placeholder="Find a role"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            className="font-medium"
            style={{ width: 300, height: 44 }}
            prefix={<SearchIcon stroke={theme.figmaColor.gray800} />}
          />
        </div>
      )}
      <Table
        columns={columns}
        rowKey="title"
        dataSource={filteredRoles}
        pagination={false}
        scroll={{ y: 500, x: 800 }}
      />
    </div>
  );
};

export default SearchRoleTable;
