import React from "react";

interface Props {
  status: string;
}

const statusColorMapping: { [key: string]: string } = {
  Invited: "text-blue-500",
  Active: "text-green-600",
  Inactive: "text-zinc-700",
};

const StatusText: React.FC<Props> = ({ status }) => {
  const statusClass = statusColorMapping[status] || "text-current";

  return <span className={`${statusClass} font-semibold`}>{status}</span>;
};

export default StatusText;
