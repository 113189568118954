import React, { useCallback, useState } from "react";
import { Form, Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PwdInput, TextInput } from "components/Input";
import { SubmitButton } from "components/Button";
import { ErrorSpace } from "components/Alert";
import { FormContainer } from "components/Layout";
import { userLogin } from "services/authService";
import parseError from "utils/error";
import { getUserInfo } from "utils/storage";
import { USER_ROLE } from "common/constants";

import AuthLogo from "./AuthLogo";
import { authRules } from "./validationRules";

const Login: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const onFinish = useCallback(
    async (values: { email: string; password: string }) => {
      setLoading(true);
      setError("");
      try {
        await userLogin({
          username: values.email,
          password: values.password,
        });
        const userInfo = getUserInfo();
        if (userInfo.role === USER_ROLE.ADMIN) {
          navigate("/admin/tenant-management");
        } else {
          navigate("/");
        }
      } catch (error_) {
        setError(parseError(error_));
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  const [form] = Form.useForm();

  return (
    <FormContainer>
      <AuthLogo />
      {error && <ErrorSpace message={error} />}
      <Form
        requiredMark={false}
        form={form}
        name="login"
        onFinish={onFinish}
        initialValues={{ remember: true }}
        className="w-full"
        layout="vertical"
      >
        <Form.Item
          name="email"
          label={
            <span className="font-bold">{t("authFlow.label.email*")}</span>
          }
          rules={authRules.email}
          className="w-full"
          normalize={(value) => value.trim()}
        >
          <TextInput placeholder="Enter your work email" />
        </Form.Item>
        <Form.Item
          name="password"
          label={
            <span className="font-bold">{t("authFlow.label.password*")}</span>
          }
          rules={authRules.password}
          className="w-full"
        >
          <PwdInput placeholder="Enter your password" />
        </Form.Item>

        <Form.Item className="w-full py-2">
          <SubmitButton form={form} loading={loading} className="w-full">
            {t("authFlow.button.login")}
          </SubmitButton>
        </Form.Item>
        <Row justify="center">
          <Col className="text-center">
            <p className="text-[14px] font-normal leading-5 font-thin">
              {t("authFlow.link.forgotPwd")}{" "}
              <a
                href="/forgot-password"
                className="font-normal text-[14px] text-primary font-bold text-purple800"
              >
                {t("authFlow.link.recoverPwd")}
              </a>
            </p>
          </Col>
        </Row>
      </Form>
    </FormContainer>
  );
};

export default Login;
