import React from "react";
import { Avatar, Button, Dropdown, MenuProps, Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "common/store";
import { logoutSuccess } from "slices/authSlice";
import isEmpty from "lodash/isEmpty";
import { getUserInfo } from "utils/storage";

const AvatarMenu: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userInfo = getUserInfo();

  const handleMenuClick = (event: { key: string }) => {
    if (event.key === "logout") {
      dispatch(logoutSuccess());
      navigate("/login");
    }
  };

  const fullName = `${userInfo?.firstName ?? ""} ${userInfo?.lastName ?? ""}`;
  const role = userInfo?.role;

  const getInitials = () => {
    if (!isEmpty(userInfo)) {
      const { firstName, lastName } = userInfo;
      if (firstName && lastName) {
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
      } else if (firstName) {
        return firstName.slice(0, 2);
      }
    }
    return "";
  };

  const avatar = (
    <Avatar size={40} className="ml-2 cursor-pointer">
      {getInitials()}
    </Avatar>
  );

  const avatarMenuItems: MenuProps["items"] = [
    {
      key: "userInfo",
      label: (
        <Tooltip title={fullName}>
          <div className="flex">
            <div>{avatar}</div>
            <div className="pl-4 font-normal">
              <p className="text-base leading-6 truncate w-24 text-white">
                {fullName}
              </p>
              <p className="text-xs leading-4 text-gray400">{role}</p>
            </div>
          </div>
        </Tooltip>
      ),
    },
    { type: "divider", className: "!bg-gray50t20" },
    {
      key: "logout",
      label: <Button type="primary">Sign Out</Button>,
      onClick: handleMenuClick,
    },
  ];

  return (
    <Dropdown
      menu={{
        items: avatarMenuItems,
        className: "w-64 !bg-gray800",
      }}
      trigger={["click"]}
    >
      {avatar}
    </Dropdown>
  );
};

export default AvatarMenu;
