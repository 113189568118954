import { gql } from "@apollo/client/core";

const GET_ME = gql`
  query Me {
    me {
      id
      email
      username
      firstName
      lastName
      role
      tenantId
      confirmed
      blocked
    }
  }
`;

export { GET_ME };
