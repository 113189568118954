import React, { useState, useEffect } from "react";
import { Button, Col, Input, message, Row } from "antd";
import Table from "components/Table/Table";
import { getRoles, upsertRole } from "services/adminService";
import { RoleRow, UpsertRolePayload } from "models/adminModel";
import { useFetchData } from "hooks";
import ErrorPage from "components/Router/ErrorPage";
import isEmpty from "lodash/isEmpty";
import parseError from "utils/error";

import DetailActions from "../DetailActions";

import PermissionModal from "./PemissionsModal";

interface Props {
  tenantId: string;
}

const RoleList: React.FC<Props> = ({ tenantId }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredRoles, setFilteredRoles] = useState<RoleRow[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState<RoleRow | undefined>();
  const [modalError, setModalError] = useState<string>("");

  const {
    data = [],
    loading,
    error,
    refetch,
  } = useFetchData<RoleRow[]>(() => getRoles(tenantId));

  useEffect(() => {
    if (!isEmpty(data)) {
      const filteredData = data.filter((item) =>
        item.name.toLowerCase().includes(searchText.trim().toLowerCase())
      );
      setFilteredRoles(filteredData);
    }
  }, [data, searchText]);

  const handleSearch = (value: string) => setSearchText(value);

  const showModal = (roleId?: string) => {
    const role = roleId ? data.find((r) => r.id === roleId) : undefined;
    setModalError("");
    setSelectedRole(role);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setModalError("");
    setIsModalVisible(false);
    setSelectedRole(undefined);
  };

  const handleOk = async (payload: UpsertRolePayload) => {
    try {
      await upsertRole(payload);
      handleCancel();
      const formatName =
        payload.name.length > 50
          ? `${payload.name.slice(0, 50)}...`
          : payload.name;
      message.success(
        `Role ${formatName} has been ${payload.id ? "updated" : "created"}.`
      );
      refetch();
    } catch (error_) {
      setModalError(parseError(error_));
    }
  };

  const roleColumns = [
    {
      title: "Role Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "# of Permissions",
      dataIndex: "totalPermissions",
      key: "totalPermissions",
      width: 180,
    },
    {
      title: "Actions",
      key: "actions",
      width: 120,
      render: (_: string, record: RoleRow) => (
        <DetailActions
          onUpdate={() => showModal(record.id)}
          updateText={record.isSystemRole ? "View Role" : "Edit Role"}
          onDelete={() => {}}
          deleteText="Delete Role"
        />
      ),
    },
  ];

  return (
    <>
      <Row gutter={[0, 0]}>
        <Col span={12}>
          <Input
            placeholder="Find a Role"
            value={searchText}
            onChange={(e) => handleSearch(e.target.value)}
            className="font-medium float-left"
            style={{ width: 300, height: 44 }}
          />
        </Col>
        <Col span={12} className="text-right pr-2">
          <Button type="primary" onClick={() => showModal()}>
            Create Role
          </Button>
        </Col>
        <Col span={24} className="pt-4">
          <Table
            columns={roleColumns}
            rowClassName={"text-base"}
            rowKey="id"
            dataSource={filteredRoles}
            loading={loading}
            pagination={false}
            scroll={{ y: 400, x: 800 }}
          />
          {error && <ErrorPage />}
        </Col>
      </Row>
      {isModalVisible && (
        <PermissionModal
          visible={isModalVisible}
          roleId={selectedRole?.id}
          tenantId={tenantId}
          onCancel={handleCancel}
          onOk={handleOk}
          error={modalError}
        />
      )}
    </>
  );
};

export default RoleList;
