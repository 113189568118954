import React, { useState } from "react";
import { Button, Col, Empty, Row } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import {
  TeamIcon as OriginalTeamIcon,
  UserCircleIcon as OriginalUserCircleIcon,
} from "components/Icon";
import { SwitchGroupButton } from "components/Button";
import { TabConfig } from "components/Button/SwitchGroupButton";
import { useNavigate, useParams } from "react-router-dom";

import TenantLayout from "../TenantLayout";

import RoleList from "./RoleList";
import UserList from "./UserList";

interface IconProps {
  fill: string;
}

const TeamIcon: React.FC<IconProps> = ({ fill }) => (
  <OriginalTeamIcon fill={fill} />
);
const UserCircleIcon: React.FC<IconProps> = ({ fill }) => (
  <OriginalUserCircleIcon fill={fill} />
);

const tabConfig: TabConfig[] = [
  {
    value: "users",
    label: "Manage Users",
    icon: TeamIcon,
  },
  {
    value: "roles",
    label: "Manage Roles",
    icon: UserCircleIcon,
  },
  {
    value: "files",
    label: "Import File",
    icon: UserCircleIcon,
  },
];

const TenantDetail: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [mode, setMode] = useState("users");
  const handleModeChange = (value: string) => {
    setMode(value);
  };

  const generateScreen = (screen: string) => {
    if (screen === "roles") {
      return <RoleList tenantId={id as string} />;
    }
    if (screen === "users") {
      return <UserList />;
    }
    return (
      <div className="h-40">
        <Empty />
      </div>
    );
  };

  const handleBackClick = () => {
    navigate("/admin/tenant-management");
  };

  return (
    <TenantLayout>
      <Row gutter={[0, 32]} className="p-8">
        <Col span={24}>
          <Button
            type="primary"
            icon={<LeftOutlined />}
            onClick={handleBackClick}
            className="pl-4"
          >
            Back
          </Button>
        </Col>
        <Col span={24}>
          <SwitchGroupButton
            defaultValue="users"
            onModeChange={handleModeChange}
            tabConfig={tabConfig}
          />
        </Col>
        <Col span={24}>{generateScreen(mode)}</Col>
      </Row>
    </TenantLayout>
  );
};

export default TenantDetail;
