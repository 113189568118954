import { gql } from "@apollo/client/core";

const UPLOAD_CSV = gql`
  mutation UploadEmployeeCsv(
    $tenantId: ID!
    $file: Upload!
    $skipError: Boolean
  ) {
    uploadEmployeeCsv(tenantId: $tenantId, file: $file, skipError: $skipError) {
      success
      error {
        missingInvalidCells
        duplicatedRows
      }
    }
  }
`;

const LIST_TENANT = gql`
  query ListTenant {
    listTenant {
      id
      name
    }
  }
`;

const LIST_ROLES = gql`
  query ListRolesWithNbOfPermissions($tenantId: ID!) {
    listRolesWithNbOfPermissions(tenantId: $tenantId) {
      id
      name
      description
      isSystemRole
      totalPermissions
    }
  }
`;

const LIST_ADMIN_PERMISSIONS = gql`
  query ListAdminPermissions {
    listAdminPermissions {
      id
      name
      action
    }
  }
`;

const LIST_TENANT_PERMISSIONS = gql`
  query ListTenantUserPermissions {
    listTenantUserPermissions {
      id
      name
      action
    }
  }
`;

const UPSERT_ROLE = gql`
  mutation UpsertRole($input: RoleInput!) {
    upsertRole(input: $input) {
      id
      name
      description
    }
  }
`;

const GET_ROLE = gql`
  query GetRoleDetail($id: ID!) {
    getRoleDetail(id: $id) {
      id
      name
      description
      isSystemRole
      permissions {
        id
        name
        action
      }
    }
  }
`;

export {
  UPLOAD_CSV,
  LIST_TENANT,
  LIST_ROLES,
  UPSERT_ROLE,
  GET_ROLE,
  LIST_ADMIN_PERMISSIONS,
  LIST_TENANT_PERMISSIONS,
};
