import _lodashGet from "lodash/get";
import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseTransformer,
  AxiosError,
} from "axios";

export default class HttpClient {
  private static readonly baseURL: string =
    process.env.REACT_APP_API_BASEURL || "http://localhost:1337";

  private static transformResponse(
    input: string
  ): AxiosResponseTransformer | AxiosResponseTransformer[] {
    return JSON.parse(input);
  }

  private static handleRequestError = (error: unknown): string => {
    if (error instanceof AxiosError) {
      return error.response && _lodashGet(error.response, "data.data.errorCode")
        ? error.response.data.data.errorMessage
        : error.message;
    }
    return "Unknown error occurred";
  };

  private static client(header: Record<string, string> = {}): AxiosInstance {
    const cancelTokenSource = axios.CancelToken.source();
    const token = localStorage.getItem("userToken");

    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : "",
      ...header,
    };

    const config: AxiosRequestConfig = {
      baseURL: this.baseURL,
      cancelToken: cancelTokenSource.token,
      headers,
    };

    config.transformResponse = [
      (data) => {
        return data && typeof data === "string"
          ? this.transformResponse(data)
          : data;
      },
    ];

    return axios.create(config);
  }

  /**
   *
   * @param url
   * @param payload
   * @returns
   */
  public static async post<T>(
    header: Record<string, string>,
    url: string,
    payload: T
  ): Promise<AxiosResponse> {
    try {
      const response = await this.client(header).post(url, payload);
      return response;
    } catch (error) {
      throw this.handleRequestError(error);
    }
  }
}
