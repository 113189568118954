import React, { ReactNode } from "react";
import { Tabs, ConfigProvider } from "antd";
import theme from "assets/styles/theme";

interface TabContent {
  title: string;
  content: ReactNode;
}

interface Props {
  tabs: TabContent[];
}

const HorizontalTabs: React.FC<Props> = ({ tabs }) => {
  const tabItems = tabs.map((tab, index) => ({
    key: index.toString(),
    label: tab.title,
    children: <div>{tab.content}</div>,
  }));

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: theme.figmaColor.purple900,
          colorBorder: theme.figmaColor.gray900t2,
        },
      }}
    >
      <Tabs
        tabPosition="left"
        defaultActiveKey="0"
        type="line"
        className="w-full"
        items={tabItems}
      />
    </ConfigProvider>
  );
};

export default HorizontalTabs;
