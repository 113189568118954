import React, { useEffect } from "react";
import { Col, Empty, Row, message } from "antd";
import { useEntityData, useTabState } from "hooks";
import {
  fetchAllTeams,
  fetchSelectedTeams,
  upsertRoadMap,
} from "services/roadMapService";
import { RoadMapPayLoad, RoadMapTeam } from "models/roadMapModel";
import FilterOptions from "components/FilterOptions";
import isEmpty from "lodash/isEmpty";
import Checkbox from "components/Checkbox";
import { PageSizeSelect, PaginationList } from "components/Pagination";
import { RowSection } from "components/Section";
import Table from "components/Table/Table";
import Loader from "components/Loading/Loader";

import CardView from "../CardView";

import TabHeader from "./TabHeader";
import ViewSwitcher from "./ViewSwitcher";

interface Props {
  onTabContentChange: (key: string) => void;
  isTabCompleted: boolean;
  onCardClick: (key: string, id: string) => void;
  selectedCardToToggle: string;
  pathId: string;
}

const Tab1Content: React.FC<Props> = ({
  onTabContentChange,
  onCardClick,
  isTabCompleted,
  selectedCardToToggle,
  pathId,
}) => {
  const {
    allEntities: allTeams,
    selectedEntities: selectedTeams,
    loading,
  } = useEntityData<RoadMapTeam>(fetchAllTeams, () =>
    fetchSelectedTeams(pathId as string)
  );

  const {
    filterOption,
    handleFilterChange,
    currentPage,
    handlePageChange,
    paginatedList,
    selectedIds,
    handleItemSelection,
    isDirty,
    setIsDirty,
    toggleViewMode,
    isTableView,
    initialSelectedItems,
    filteredList,
    pageSize,
    setPageSize,
  } = useTabState<RoadMapTeam>({
    initialItems: allTeams,
    selectedItems: selectedTeams,
  });

  const selectedCount = selectedIds.size;

  const handleItemClick = (id: string) => {
    if (isTabCompleted) {
      onCardClick("1", id);
    } else {
      handleItemSelection(id);
    }
  };

  useEffect(() => {
    setIsDirty(false);
    if (selectedCardToToggle) {
      handleItemSelection(selectedCardToToggle);
    }
  }, [selectedCardToToggle]);

  const handleSaveAndContinue = async () => {
    const newTeams = allTeams.filter((team: RoadMapTeam) =>
      selectedIds.has(team.id)
    );
    try {
      const payload: RoadMapPayLoad = {
        id: pathId,
        teams: newTeams,
        tasks: [],
        roles: [],
      };
      await upsertRoadMap(payload);
      message.success("Teams successfully saved.");
      initialSelectedItems.current = new Set(selectedIds);
      onTabContentChange("1");
    } catch {
      message.error("Failed to save teams. Please try again.");
    }
  };

  const columns = [
    {
      title: "",
      dataIndex: "select",
      key: "select",
      width: 60,
      render: (_: string, record: RoadMapTeam) => (
        <Checkbox isChecked={selectedIds.has(record.id)} />
      ),
    },
    {
      title: "Team Name",
      dataIndex: "name",
      key: "name",
      render: (_: string, record: RoadMapTeam) => (
        <div
          className="text-base font-normal leading-6 w-auto"
          title={record.name}
        >
          {record.name}
        </div>
      ),
    },
    {
      title: "Employees",
      dataIndex: "totalEmployees",
      key: "totalEmployees",
      width: 180,
      render: (_: string, record: RoadMapTeam) => (
        <RowSection value={record.totalEmployees} />
      ),
    },
    {
      title: "Monthly Hours",
      dataIndex: "monthlyTimeSaved",
      key: "monthlyTimeSaved",
      width: 180,
      render: (_: string, record: RoadMapTeam) => (
        <RowSection value={record.monthlyTimeSaved} />
      ),
    },
  ];

  return (
    <>
      <TabHeader
        title="Choose your teams."
        description="Selected the teams that you want to include in this roadmap for adoption of the products you chose."
        tabStep="1"
        isDirty={isDirty}
        selectedCount={selectedCount}
        onTabContentChange={handleSaveAndContinue}
      />
      <div className="p-4">
        <Row gutter={16} className="mb-4 flex items-center">
          <Col span={8} className="flex items-center">
            <div className="text-lg font-bold leading-7 pr-3">Show</div>
            <FilterOptions
              filterOption={filterOption}
              onChange={handleFilterChange}
              orderByLabel=""
              radioOptions={[
                { value: "allItems", label: "All Teams" },
                { value: "selectedItems", label: "Selected Teams" },
              ]}
            />
          </Col>
          {/* FIXME: hide this feature will open next sprint  */}
          <Col span={16} className="hidden">
            <div className="flex justify-end items-center space-x-4">
              <PageSizeSelect
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <ViewSwitcher
                isTableView={isTableView}
                toggleViewMode={toggleViewMode}
              />
            </div>
          </Col>
        </Row>

        {loading ? (
          <Loader />
        ) : isTableView ? (
          <Table
            columns={columns}
            rowKey="id"
            dataSource={paginatedList}
            pagination={false}
            onRow={(record) => ({
              onClick: () => handleItemClick(record.id),
              className: "cursor-pointer",
            })}
          />
        ) : (
          <Row>
            {isEmpty(paginatedList) ? (
              <Empty />
            ) : (
              paginatedList.map((team) => (
                <Col xs={24} md={8} lg={8} key={team.id}>
                  <CardView
                    tabKey={"1"}
                    id={team.id}
                    title={team.name}
                    monthlyHours={team.monthlyTimeSaved}
                    totalEmployees={team.totalEmployees}
                    isActive={selectedIds.has(team.id)}
                    onCheck={() => handleItemClick(team.id)}
                    isTabCompleted={isTabCompleted}
                    onCardClick={onCardClick}
                  />
                </Col>
              ))
            )}
          </Row>
        )}

        <Row>
          <div className="flex justify-start pt-4">
            <PaginationList
              currentPage={currentPage}
              pageSize={pageSize}
              total={filteredList.length}
              onPageChange={handlePageChange}
            />
          </div>
        </Row>
      </div>
    </>
  );
};

export default Tab1Content;
