import React, { useState, useEffect, useRef, useCallback } from "react";
import { Modal } from "antd";
import useFetchData from "hooks/useFetchData";
import { getRolePermissions } from "services/adminService";
import { isEmpty } from "lodash";
import HorizontalTabs from "components/HorizontalTabs";
import {
  convertPayloadToCombinedPermissions,
  Permission,
  UpsertRolePayload,
  RoleDetail,
} from "models/adminModel";
import { ErrorSpace } from "components/Alert";

import PermissionsTable from "./PermissionsTable";
import PermissionDetail from "./PermissionDetail";

interface Props {
  visible: boolean;
  roleId?: string;
  tenantId?: string;
  onCancel: () => void;
  onOk: (payload: UpsertRolePayload) => void;
  error?: string;
}

const PermissionModal: React.FC<Props> = ({
  visible,
  roleId,
  tenantId,
  onCancel,
  onOk,
  error = "",
}) => {
  const [formValues, setFormValues] = useState({ name: "", description: "" });
  const [isPermissionSelected, setIsPermissionSelected] = useState(false);
  const [isOkButtonEnabled, setIsOkButtonEnabled] = useState(false);
  const [isSystemRole, setIsSystemRole] = useState(false);
  const [permissions, setPermissions] = useState<Permission[]>([]);

  const hasFetchedData = useRef(false);

  const fetchRoleData = useFetchData<RoleDetail>(() =>
    getRolePermissions(roleId)
  );
  const { data, loading } = fetchRoleData;

  useEffect(() => {
    if (visible && !hasFetchedData.current) {
      fetchRoleData.refetch();
      hasFetchedData.current = true;
    } else if (!visible) {
      hasFetchedData.current = false;
    }
  }, [visible, fetchRoleData]);

  useEffect(() => {
    if (!isEmpty(data)) {
      setFormValues({ name: data.name, description: data.description });
      setIsSystemRole(data.isSystemRole);
      setPermissions(data.rolePermissions);

      const isSelected = data.rolePermissions.some((p) =>
        Object.values(p.permissions).some(Boolean)
      );
      setIsPermissionSelected(isSelected);
    }
  }, [data]);

  useEffect(() => {
    const isFormValid =
      formValues.name.trim() !== "" && formValues.description.trim() !== "";
    setIsOkButtonEnabled(isFormValid && isPermissionSelected);
  }, [formValues, isPermissionSelected]);

  const handleRoleFormChange = useCallback((field: string, value: string) => {
    setFormValues((prev) => ({ ...prev, [field]: value }));
  }, []);

  const handlePermissionsChange = useCallback(
    (isSelected: boolean, permissionData: Permission[]) => {
      setIsPermissionSelected(isSelected);
      setPermissions(permissionData);
    },
    []
  );

  const handleOkChange = () => {
    const payload: UpsertRolePayload = {
      id: roleId,
      ...formValues,
      tenant: tenantId,
      permissions: convertPayloadToCombinedPermissions(permissions),
    };
    onOk(payload);
  };

  const tabs = [
    {
      title: "Details",
      content: (
        <PermissionDetail
          name={formValues.name}
          description={formValues.description}
          onChange={handleRoleFormChange}
          disabled={isSystemRole}
        />
      ),
    },
    {
      title: "Permissions",
      content: (
        <PermissionsTable
          permissions={permissions}
          onPermissionChange={handlePermissionsChange}
          disabledCheckBox={isSystemRole}
        />
      ),
    },
  ];

  const title = roleId
    ? isSystemRole
      ? `View Role - ${formValues.name}`
      : `Edit Role - ${formValues.name}`
    : "Create Role";

  return (
    <Modal
      maskClosable={false}
      title={title}
      width="800px"
      open={visible}
      onCancel={onCancel}
      cancelText={isSystemRole ? "Close" : "Cancel"}
      onOk={handleOkChange}
      okText={roleId ? "Update" : "Create"}
      okButtonProps={{
        disabled: !isOkButtonEnabled,
        hidden: isSystemRole,
      }}
      centered
      loading={loading}
    >
      {error && <ErrorSpace message={error} />}
      <HorizontalTabs tabs={tabs} />
    </Modal>
  );
};

export default PermissionModal;
