import Loader from "components/Loading/Loader";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { fetchUserInfo } from "services/userService";
import { getUserToken } from "utils/storage";
import { useFetchData } from "hooks";
import { MainHeader } from "components/Header";

interface Props {
  roleRequired?: string[];
}

const ProtectedRoute: React.FC<Props> = ({ roleRequired }) => {
  const isAuth = !!getUserToken();

  const {
    data: role,
    loading,
    error,
  } = useFetchData(fetchUserInfo, (data) => data.role);

  if (loading) {
    return <Loader />;
  }

  if (!isAuth || error) {
    return <Navigate to="/login" />;
  }

  if (roleRequired && role && !roleRequired.includes(role)) {
    return <Navigate to="/denied" />;
  }

  return (
    <>
      <MainHeader role={role} />
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
