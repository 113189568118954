import React from "react";
import { Button, Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";

interface Props {
  onCreate?: () => void;
  onUpdate?: () => void;
  onDelete?: () => void;
  onResendInvite?: () => void;
  createText?: string;
  updateText?: string;
  deleteText?: string;
  resendInviteText?: string;
}

const DetailActions: React.FC<Props> = ({
  onCreate,
  onUpdate,
  onDelete,
  onResendInvite,
  createText = "Create",
  updateText = "Edit",
  deleteText = "Delete",
  resendInviteText = "Re-send invite",
}) => {
  const content = (
    <div className="flex flex-col space-y-2">
      {onCreate && (
        <Button type="text" className="justify-start w-full" onClick={onCreate}>
          {createText}
        </Button>
      )}
      {onUpdate && (
        <Button type="text" className="justify-start w-full" onClick={onUpdate}>
          {updateText}
        </Button>
      )}
      {onResendInvite && (
        <Button
          type="text"
          className="justify-start w-full"
          onClick={onResendInvite}
        >
          {resendInviteText}
        </Button>
      )}
      {onDelete && (
        <Button type="text" className="justify-start w-full" onClick={onDelete}>
          {deleteText}
        </Button>
      )}
    </div>
  );

  return (
    <Popover placement="right" content={content} trigger="hover">
      <MoreOutlined className="cursor-pointer text-2xl" />
    </Popover>
  );
};

export default DetailActions;
